/*
*   Script to parse xml-files
*
* */
const xml2js = require('xml2js');

/**
 * returns the columns from an xml as a string array
 * @params xml {String} the xml as a string
 * @returns {Array} the column names as an array
 * */
export const getColumnNames = async(xml) => {
    const {colNames} = await convertXMLToObject(xml);
    return colNames;
};

const normalizeXML = (object) => {
    const allRows = Object.values(object)[0];
    const rowProp = Object.keys(allRows)[0];
    const rows = allRows[rowProp];

    console.log('rows', rows[0]); // rows[0] = 'Cell' => [ 'name', 'bereich', 'anzahl' ]

    const topRow = rows[0];
    const amountOfKeys = Object.keys(topRow).length;
    const colNames = amountOfKeys > 1 ? Object.keys(rows[0]) : Object.values(rows[0])[0];
    const data = rows.map(row => {
        if (Array.isArray(row)) {
            const rowObject = {};
            for (let index = 0; index < colNames.length; index++) {
                rowObject[colNames[index]] = row[index];
            }
            return rowObject;
        }
        return row;
    });

    console.log('colNames', colNames);
    console.log('data', data);

    return { colNames, data };
};

/**
 * returns the data from an xml as a string array
 * @params xml {String} the xml as a string
 * @returns {Array} the xml data rows as an array
 * */
 export const getData = async(xmlString) => {
    const columns = await this.getColumnNames(xmlString);

    const object = await convertXMLToObject(xmlString);
    const items = Object.keys(object);
    if(items.length > 1) {
        throw new Error('XML has multiple roots')
    }
    const rows = object[items[0]].record;

    const result = [];
    for(let i = 0; i < rows.length; i++) {
        let object = {};
        for(let j = 0; j < columns.length; j++) {
            let columnName = columns[j];
            const str = rows[i][columnName][0];
            object[columnName] = str ? str.trim() : '';
        }
        result.push(object);
    }
    return result;
};


/**
 * Converts an XML-String to an object
 * @param xml {String}
 * @returns {Object} the object representation of the xml
 * - the object has the following structure:
 * {colNames: [String], data: [Object]}
 * */
const convertXMLToObject = async (xml) => {
    return new Promise((resolve, reject) => {
        const parser = new xml2js.Parser({ explicitArray: false });
        parser.parseString(xml, (err, result) => {
            if (err) reject(err);
            else resolve(normalizeXML(result));
        });
    });
}
